import { Env } from './environment.model';
export const environment: Env = {
  production: true,
  disableLogs: false,
  auth: {
    isLocalLoginEnabled: false,
    redirectUrl: 'https://www.rc.radioking.com/login?redirect=https:%2F%2Fmobile.rc.radioking.com',
  },
  urls: {
    MAIN_API: 'https://api.rc.radioking.io',
    MOBILE_API: 'https://www.rc.radioking.com/mobile/api',
    RADIOKING_DOMAIN: 'rc.radioking.com',
    SIMULATOR: 'https://mobile-simulator.rc.radioking.com'
  },
  whmcs: {
    url: 'https://www.rc.radioking.com/on',
    basic: {
      customFieldId: '173',
      planId: '59',
      publicationAddonId: '17',
    },
    smart: {
      customFieldId: '179',
      planId: '61',
      publicationAddonId: '19',
    },
    expert: {
      customFieldId: '183',
      planId: '63',
      publicationAddonId: '19',
    },
  },
  googleMapsKey: 'AIzaSyA1BNLwxFxrbnVeH5Er5387UFI0G2xeJjQ',
  gtm: 'GTM-PVP6SKT',
  amplitudeKey: 'f1f1db4a59862a02677e5e812857fc24',
  cookies: {
    config: {
      domain: '.rc.radioking.com',
      expires: 365,
      secure: true,
      path: '/',
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
};
